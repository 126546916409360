<template>
  <div class="logout-container">
    <div class="login-pane">
      <i class="el-icon-loading" />
      <h1>安全认证注销中，请稍等</h1>
    </div>
  </div>
</template>

<script>
import loginApi from '@/service/api/login/login-api'
import authorization from '@/store/utils/authorization'
export default {
  name: 'LoginOut',
  mounted () {
    // 登出
    loginApi.logout(authorization.getToken()).finally(() => {
      // 清空登录信息
      authorization.handleLogout()
      // 页面跳转
      this.$router.replace('/index')
    })
  }
}
</script>
<style scoped lang="scss">
.logout-container{
  height: 100vh;
  width: 100%;
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: #888888;
  .login-pane{
    width: 280px;
    height: 140px;
    background-color: #ffffff;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    i{
      font-size: 48px;
      color: #409eff;
    }
    h1{
      font-size: 20px;
    }
  }
}
</style>
